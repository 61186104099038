//REGISTER
export const POST_JWT_REGISTER = "/api/auth/signup";


//LOGIN
export const POST_JWT_LOGIN = "/api/auth/signin";

//LOGIN
export const POST_GET_USER_INFO = "/api/user/get";


//Media
export const POST_MEDIA_CREATE = "/api/media/create";
export const GET_HEADING_IMAGES = "/api/media/get_heading_images";
export const GET_IMAGE_BY_ID = "/api/media/fileById";
export const DELETE_MEDIA = "/api/media/delete";
export const GET_PRESIGNED_URL = "/api/media/generate_presigned_url";


//Client Review
export const GET_ALL_REVIEWS = "/api/client_review/get_All";
export const GET_ONE_REVIEW_BY_ID = "/api/client_review/getOneClientReview";
export const POST_CREAT_REVIEW = "/api/client_review/create";
export const UPDATE_CLIENT_REVIEW = "/api/client_review/update";
export const DELETE_REVIEW = "/api/client_review/delete";

//Domain.com.au
export const UPLOAD_LISTINGS_DOMAIN = "/api/domain/upload_listing";

//REA
export const UPLOAD_LISTINGS_REA = "/api/rea/upload_listing_xml";
export const GET_ALL_REA_PORTALS = "/api/rea/get_All";

// Listing portal

export const GET_ALL_LISTING_PORTALS = "/api/listing_portal/get_All";
export const GET_ONE_PORTAL_BY_ID = "/api/listing_portal/getOneListingPortal";
export const POST_CREAT_PORTAL = "/api/listing_portal/create";
export const UPDATE_CLIENT_PORTAL = "/api/listing_portal/update";
export const DELETE_PORTAL = "/api/listing_portal/delete";

// Listings(new)

export const GET_ALL_LISTINGERS = "/api/listinger/get_All";
export const GET_ACTIVE_LISTINGERS = "/api/listinger/get_all_active";


export const GET_ONE_LISTINGER_BY_ID = "/api/listinger/getOneListinger";
export const POST_CREAT_LISTINGER = "/api/listinger/create";
export const UPDATE_CLIENT_LISTINGER = "/api/listinger/update";
export const DELETE_LISTINGER = "/api/listinger/delete";
export const UPLOAD_LISTING_IMAGE = "/api/listinger/uploadImage";
export const UPLOAD_LISTING_MEDIA = "/api/listinger/uploadMedia";
export const GET_ADDRESS_BY_PLACE_ID = "/api/listinger/getAddressByPlaceId";
export const CREATE_LISTING_PDF = "/api/listinger/createPdf";
export const CREATE_STOCKLIST_PDF = "/api/listinger/createPdfStocklist";
export const FETCH_LISTINGS_WITH_FILTERS = "/api/listings/fetchWithFilters";
export const GET_ALL_UNIQUE_PROPERTY_TAGS = "/api/listinger/get_all_unique_property_tags";





// Listing Agent

export const GET_ALL_LISTINGAGENTS = "/api/listing_agent/get_All";
export const GET_ONE_LISTINGAGENT_BY_ID = "/api/listing_agent/getOneListinger";
export const POST_CREAT_LISTINGAGENT = "/api/listing_agent/create";
export const UPDATE_LISTINGAGENT = "/api/listing_agent/update";
export const LISTING_AGENT_API_URL = "/api/listing-agents";

export const DELETE_LISTINGAGENT = "/api/listing_agent/delete";


export const CONTACT_API_URL = "/api/contact";
export const PREFERENCE_API_URL = "/api/preference";

export const TASK_API_URL = "/api/tasks";
export const BLOG_API_URL = "/api/blogs";


// Marketing
export const CREATE_MARKETING_CAMPAIGN = "/api/campaign/create";
export const GET_ALL_MARKETING_CAMPAIGNS = "/api/campaign/all";
export const GET_ONE_MARKETING_CAMPAIGN_BY_ID = "/api/campaign/get";
export const UPDATE_MARKETING_CAMPAIGN = "/api/campaign/update";
export const DELETE_MARKETING_CAMPAIGN = "/api/campaign/delete";
export const ACTIVATE_MARKETING_CAMPAIGN = "/api/campaign/activate";
export const GET_CONTACT_TAGS = "/api/contact/get_tags";
export const GET_EMAIL_PREVIEW = "/api/campaign/previewemail";


// Branding URLs
export const CREATE_BRANDING = '/api/branding/create';
export const GET_BRANDING_BY_CLIENT_ID = '/api/branding/getByClientId';
export const GET_CONTACT_BY_CLIENT_ID = '/api/branding/getContactByClientId';

export const UPDATE_BRANDING = '/api/branding/update';

// Social URLs
export const UPDATE_SOCIAL_URLS = '/api/branding/updateSocialUrls'; // Adjust the endpoint as needed
export const UPDATE_CONTACT_INFO = '/api/contact/update'; // Adjust according to your actual endpoint
// Notes URLs
export const CREATE_NOTE = '/api/note';
export const GET_NOTES_BY_CONTACT_ID = '/api/notes'; // The contactId will be appended dynamically
export const UPDATE_NOTE = '/api/note'; // The noteId will be appended dynamically
export const DELETE_NOTE = '/api/note'; 