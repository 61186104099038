import React from "react";
import { Redirect, useLocation } from "@reach/router";
// import { AuthenticatedLayout } from "components/Layouts/AuthenticatedLayout";

import { useStore } from "../utils/helpers";
import { observer } from "mobx-react-lite";

export const PrivateRoute = observer<{component,path?,default?}>((props) => {
  const rootStore = useStore();
  const location = useLocation();
  const { userStore } = rootStore;
  const { isLoggedIn } = userStore;

  const { component: Component, ...rest } = props;

  // if (!isLoggedIn) {
  //   navigate("/login", { state: { from: location.pathname } });
  // }

  return (
    <div>
      {isLoggedIn ? (
          <Component {...rest} />
      ) : (
        <Redirect from={location.pathname} to="/login" noThrow />
      )}
    </div>
  );
});

export const PublicRoute = observer<any>((props) => {
  const { component: Component, ...rest } = props;
  return <Component {...rest} />;
});
