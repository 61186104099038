import {
  observable,
  action,
  // computed
} from "mobx";

export class NavbarStore {
  @observable isOpen: any[] = []; //for active default menu
  @observable isTrigger: any[] = [];

  @observable layout = "vertical";
  @observable preLayout = "";
  @observable layoutType = "menu-light";
  @observable navBackColor = "navbar-default"; // navbar-default, (navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark are not available in lite version)
  @observable navBrandColor = "brand-default"; // brand-default, (brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark are not available in lite version)
  @observable headerBackColor = "header-default"; //header-default, (header-blue, header-red, header-purple, header-lightblue, header-dark are not available in lite version)
  @observable headerFixedLayout: boolean = false;
  @observable navDropdownIcon = "style1";
  @observable navListIcon = "style1";
  @observable navActiveListColor = "active-default";
  @observable navListTitleColor = "title-default";
  @observable navBackImage?: string;
  @observable navIconColor: boolean = false;
  @observable configBlock: boolean = false;
  @observable navFixedLayout: boolean = true;
  @observable navListTitleHide: boolean = false;
  //   @observable isSideBarCollapsed1: boolean = false;
  @observable layout6Background =
    "linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)";
  @observable layout6BackSize = "";
  @observable boxLayout: any;
  @observable isSideBarCollapsed: boolean = false;

  rootStore: any;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
  }

  @action
  toggleMinimizeSideBar = () => {
    this.isSideBarCollapsed = !this.isSideBarCollapsed;
  };

  @action
  onChangeLayout = (layoutClass: string) => {
    this.layout = layoutClass;
  };

  @action
  onCollapseToggle = (id: string, type: string | undefined) => {
    let open = this.isOpen;
    let trigger = this.isTrigger;

    if (type === "sub") {
      const triggerIndex = trigger.indexOf(id);
      if (triggerIndex > -1) {
        open = open.filter((item) => item !== id);
        trigger = trigger.filter((item) => item !== id);
      }

      if (triggerIndex === -1) {
        open = [...open, id];
        trigger = [...trigger, id];
      }
    } else {
      open = this.isOpen;
      const triggerIndex = this.isTrigger.indexOf(id);
      trigger = triggerIndex === -1 ? [id] : [];
      open = triggerIndex === -1 ? [id] : [];
    }

    this.isOpen = open;
    this.isTrigger = trigger;
  };

  @action
  onToggleNavigation = () => {
    this.toggleMinimizeSideBar();
  };

  @action
  onNavCollapseLeave = (id, type) => {
    if (type && type === "sub") {
      let open = this.isOpen;
      let trigger = this.isTrigger;

      const triggerIndex = trigger.indexOf(id);
      if (triggerIndex > -1) {
        open = open.filter((item) => item !== id);
        trigger = trigger.filter((item) => item !== id);
      }

      this.isOpen = open;
      this.isTrigger = trigger;
    }
  };
  // dispatch({type: actionTypes.NAV_COLLAPSE_LEAVE, menu: {id: id, type: type}})

  //   @action
  //   removeTodo = (todo: TodoItem) => {
  //     this.list.splice(this.list.indexOf(todo), 1);
  //   };
}
