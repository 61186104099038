
import { NavbarStore } from "./navbar";
import { UserStore } from "./user";

export class RootStore {
  userStore: UserStore;
  navbarStore: NavbarStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.navbarStore = new NavbarStore(this);
  }
}
