import { getUserInfos, updateAuthenticatedUser } from './../clients/Crm/Api/backend_helper';
import { observable, action, computed } from "mobx";
// @ts-ignore
// import { jwtVerify } from "jose";
// import { parseJwk } from "jose/jwk/parse";
// import { UnsecuredJWT } from "jose";

import jwt from "jwt-simple";
import { LoginResponse } from "clients/AuthClient";
import { act } from "@testing-library/react";
import { getProfileImage } from "clients/Crm/UsersClient";

enum ClaimType {
  Role = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role",
  Surname = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname",
  NameIdentifier = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier",
  Name = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name",
  MobilePhone = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/mobilephone",
  Email = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress",
  GivenName = "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname",
  ProfilePictureUri = "http://schemas.realsoftware.com.au/crm/identity/claims/ProfilePictureUri",
}

type User = {
  ClientId: string;
  UserId: string;
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string[];
  profilePictureUri
};

export class UserStore {
  @observable hasCheckedAuth: boolean = false;
  @observable name: string = "";
  @observable username: string = "";
  @observable email: string = "";
  @observable isAuthorized: boolean = false;
  @observable permissions: string[] = [];

  rootStore: any;

  constructor(rootStore: any) {
    this.rootStore = rootStore;
    this.isAuthorized = false;
    this.hasCheckedAuth = false;
  }

  getUser = (decodedToken: any): User => {
    const user = {
      UserId: decodedToken['UserId'],
      id: decodedToken['UserId'],
      email: decodedToken['email'],
      firstName: decodedToken['firstName'],
      lastName: decodedToken['lastName'],
      name: decodedToken['email'],
      // id: decodedToken[ClaimType.NameIdentifier],
      // name: decodedToken[ClaimType.Name],
      // firstName: decodedToken[ClaimType.GivenName],
      // lastName: decodedToken[ClaimType.Surname],
      // email: decodedToken[ClaimType.Email],
      // profilePictureUri: decodedToken[ClaimType.ProfilePictureUri],
    };

    return user as any as User;
  };

  @action
  login = async (res: LoginResponse) => {
    // JWT.verify(res.token, "secret", (err, decoded) => {
    const decoded = jwt.decode(
      res.accessToken,
      "bezkoder-secret-key",
      false
    );

    const user = this.getUser(decoded);
    // const { payload, protectedHeader } = await jwtVerify(res.accessToken, '', {
    //   audience: `${process.env.REACT_APP_TOKEN_AUDIENCE}`,
    //   issuer: `${process.env.REACT_APP_TOKEN_ISSUER}`,
    // })
    this.name = user.name;
    this.isAuthorized = true;

    window.localStorage.setItem("authUser", JSON.stringify(res));

    var userInfo = await getUserInfos({ email: user.email });

    updateAuthenticatedUser({ ...res, ...userInfo });

    window.localStorage.setItem("__accessToken", res.accessToken);
    window.localStorage.setItem("__refreshToken", res.refreshToken);
    window.localStorage.setItem("__name", user.name);
    window.localStorage.setItem("__username", user.email);
    window.localStorage.setItem("__profilePictureUri", user.profilePictureUri);
  };

  @action
  checkLogin = () => {
      if (!this.hasCheckedAuth) {
          this.hasCheckedAuth = true;
          const username = window.localStorage.getItem("__username");
          const name = window.localStorage.getItem("__name");
          if (name) {
              this.name = name;
              this.isAuthorized = true;
          }
      }
  };
  

  @action
  logout = () => {
    this.name = "";
    this.isAuthorized = false;
    window.localStorage.removeItem("__accessToken");
    window.localStorage.removeItem("__refreshToken");
    window.localStorage.removeItem("__name");
    window.localStorage.removeItem("__username");

    window.localStorage.removeItem("__profilePictureUri");
  };

  @computed
  get isLoggedIn(): boolean {
    return this.isAuthorized;
  }
  @action
  getUserInfo = () => {
    return {
      userName: window.localStorage.getItem("__username") ?? "",
      name: window.localStorage.getItem("__name") ?? "",
      profilePictureUri: window.localStorage.getItem("__profilePictureUri") ?? ""
    }
  }
  @action
  getUserImage = async () => {
    const res = await getProfileImage();
    const image = URL.createObjectURL(res);
    return image;
  }
}