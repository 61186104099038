import axios from "axios";
// default
axios.defaults.baseURL = process.env.REACT_APP_API_NEW_URL;
// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    let message;
    switch (error.status) {
      case 500:
        message = "Internal Server Error";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      case 404:
        message = "Sorry! the data you are looking for could not be found";
        break;
      default:
        message = error.message || error;
    }
    return Promise.reject(message);
  }
);
/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["x-access-token"] = token;
};

class APIClient {
  /**
   * Fetches data from given url
   */

  //  get = (url, params) => {
  //   return axios.get(url, params);
  // };
   get = (url, params = null) => {
    let response;

    if (params) {
      const queryString = new URLSearchParams(params).toString();
      response = axios.get(`${url}?${queryString}`);
    } else {
      response = axios.get(url);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };

  /**
   * post given data to url
   */
  postFormData = (url, formData) => {
    return axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };
// Add this method if it doesn't exist in your API client
postJson = (url, data) => {
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config = null) => {
    if (config) {
      return axios.delete(url, config);
    } else {
      return axios.delete(url);
    }
  };
}
const getLoggedinUser = () => {
  const user = localStorage.getItem("authUser");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export { APIClient, setAuthorization, getLoggedinUser };