import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { RootStore } from "./stores/root";
import { StoreProvider } from "./utils/store-provider";

import { createHistory, HistorySource, LocationProvider } from "@reach/router";

import "./index.scss";

let history = createHistory(window as any as HistorySource);

const initialRoot = new RootStore();

ReactDOM.render(
  <StoreProvider value={initialRoot}>
    <LocationProvider history={history}>
      <App />
    </LocationProvider>
  </StoreProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();