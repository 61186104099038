import { useContext, KeyboardEvent, useRef, useEffect, useState } from "react";
import { StoreContext } from "./store-provider";
import { RootStore } from "../stores/root";

export const onEnterPress = (cb: any) => {
  return (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      cb();
    }
  };
};

export const useStore = (): RootStore => useContext(StoreContext);

export const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

interface WindowSizeState {
  width?: number;
  height?: number;
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<WindowSizeState>({});
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export function enumKeys(myEnum: any): { [k: string]: string } {
  return Object.keys(myEnum)
    .filter((o) => {
      const i = parseInt(o);

      if(!isNaN(i)) return false;

      return typeof o == "string";
    })
    .reduce((prev, curr) => {
      prev[curr] = myEnum[curr];
      return prev;
    }, {});
}



/**
 * 
 * @param fn function execute on timeout
 * @param delay delay time in ms
 * @param deps 
 * @param allowTrigger 
 */
 export const useDebounceWithTrigger = (
  fn: () => void,
  delay: number,
  deps: any[],
  allowTrigger?: boolean
) => {
  useEffect(
    () => {
      // Update debounced value after delay
      let handler: any | null = null;
      if (allowTrigger) {
        handler = setTimeout(() => {
          fn();
        }, delay);
      }

      return () => {
        if (handler != null) {
          clearTimeout(handler);
        }
      };
    },
    [...deps, delay] // Only re-call effect if value or delay changes
  );
};
