import {
  Router,
  useLocation,
} from "@reach/router";

import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import Loadable, { LoadingComponentProps } from "react-loadable";
import { usePrevious, useStore } from "./utils/helpers";
import { PublicRoute } from "./routes/helpers";
import "react-datetime/css/react-datetime.css";
const Loading = (props: LoadingComponentProps) => {
  if (props.isLoading) {
    if (props.timedOut) {
      return <div>Loader timed out!</div>;
    } else if (props.pastDelay) {
      return <div>Loading...</div>;
    } else {
      return null;
    }
  } else if (props.error) {
    return <div>Error! Component failed to load</div>;
  } else {
    return null;
  }
};

const AuthorizedRoutes = Loadable({
  loader: () => import("routes/all"),
  loading: Loading,
  render(loaded, props) {
    const C: any = loaded.default;
    return <C {...props} />;
  },
});

// const GeneralLayout = Loadable({
//   loader: () => import("./components/Layouts/AuthenticatedLayout"),
//   loading: Loading,
//   render(loaded, props) {
//     const C: any = loaded.AuthenticatedLayout;
//     return <C {...props} />;
//   },
// });
// const ResetPassword  = Loadable({
//   loader: () => import("./pages/Auth/ResetPassword"),
//   loading: Loading,
//   render(loaded, props) {
//     const C: any = loaded.default;
//     return <C {...props} />;
//   },
// });

const ResetPassword = Loadable({
  loader: () => import("./pages/Auth/ResetPassword"),
  loading: Loading,
});



const Signin = Loadable({
  loader: () => import("./pages/Auth/Login"),
  loading: Loading,
  render(loaded, props) {
    const C: any = loaded.default;
    return <C {...props} />;
  },
});

const Logout = Loadable({
  loader: () => import("./pages/Auth/Logout"),
  loading: Loading,
  render(loaded, props) {
    const C: any = loaded.default;
    return <C {...props} />;
  },
});

const App: React.FC = observer((props) => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  const { isAuthorized, hasCheckedAuth } = userStore;

  const prevAuthorized = usePrevious(isAuthorized);
  const location = useLocation();

  useEffect(() => {

    if (!hasCheckedAuth) {
      userStore.checkLogin();
    } else {

      //   if (!prevAuthorized && isAuthorized) {

      //     navigate("/");
      //   } else if (!isAuthorized && prevAuthorized) {

      //     navigate("/login");
      //   } else {
      //     navigate("/login", { state: { from: location.pathname } });
      //   }
    }
  }, [
    prevAuthorized,
    isAuthorized,
    hasCheckedAuth,
    userStore,
    location.pathname,
  ]);

  return (
    <Router>
      <PublicRoute path="/login" component={Signin} />
      <PublicRoute path="/logout" component={Logout} />
      <PublicRoute path="/reset-password" component={ResetPassword} /> 

      <PublicRoute default component={AuthorizedRoutes} />
    </Router>
  );
});

export default App;
