import { JSON_BODY_HEADER } from "clients/constants";

// Updated to align with new user management functionalities
export const getUsersList = async () => {
  const token = localStorage.getItem("__accessToken");
  const response = await fetch(
    `${process.env.REACT_APP_API_NEW_URL}/api/users`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) throw new Error("Failed to fetch users");

  return response.json();
};

// Assuming you decide to keep getAgentsList for simplicity or specific use cases
export const getAgentsList = async (clientId) => {
  const token = localStorage.getItem("__accessToken");

  const response = await fetch(
    `${
      process.env.REACT_APP_API_NEW_URL
    }/api/listing-agents/search?clientId=${encodeURIComponent(clientId)}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (!response.ok) throw new Error("Failed to fetch agents");
  return response.json();
};

// Update createUser function in your User Helper to include AgentId
export const createUser = async (userData) => {
  const token = localStorage.getItem("__accessToken");
  const formattedUserData = {
    firstName: userData.FirstName,
    lastName: userData.LastName,
    email: userData.Email,
    password: userData.Password,
    agentId: userData.AgentId, // Include agentId in the request
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_NEW_URL}/api/users`,
    {
      method: "POST",
      body: JSON.stringify(formattedUserData),
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }
  );

  if (!response.ok) {
    const errorResponse = await response.json();
    throw new Error(
      `Failed to create user: ${errorResponse.message || "Unknown error"}`
    );
  }

  return response.json();
};

export const updateUser = async (userId, updateData) => {
  const token = localStorage.getItem("__accessToken");
  const response = await fetch(
    `${process.env.REACT_APP_API_NEW_URL}/api/users/${userId}`,
    {
      // Updated path
      method: "PUT",
      body: JSON.stringify(updateData),
      headers: {
        Authorization: `Bearer ${token}`,
        ...JSON_BODY_HEADER,
      },
    }
  );
  return response.json();
};

export const deleteUser = async (userId) => {
  const token = localStorage.getItem("__accessToken");
  const response = await fetch(
    `${process.env.REACT_APP_API_NEW_URL}/api/users/${userId}`,
    {
      // Updated path
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.json();
};

export const getOneUserByEmail = async (email) => {
  const token = localStorage.getItem("__accessToken");
  const response = await fetch(
    `${process.env.REACT_APP_API_NEW_URL}/api/user/get`,
    {
      // Make sure this matches your backend route
      method: "POST",
      body: JSON.stringify({ email }),
      headers: {
        Authorization: `Bearer ${token}`,
        ...JSON_BODY_HEADER,
      },
    }
  );
  return response.json();
};

//oldddd below

export const changeEmail = async (oldEmail, newEmail) => {
  const token = localStorage.getItem("__accessToken");
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/users/change-email`,
    {
      method: "POST",
      body: JSON.stringify({
        oldEmail: oldEmail,
        newEmail: newEmail,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        ...JSON_BODY_HEADER,
      },
    }
  );
  return {};
};
export const changePassword = async (oldPassword, newPassword) => {
  const token = localStorage.getItem("__accessToken");
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/users/change-password`,
    {
      method: "POST",
      body: JSON.stringify({
        oldPassword: oldPassword,
        newPassword: newPassword,
      }),
      headers: {
        Authorization: `Bearer ${token}`,
        ...JSON_BODY_HEADER,
      },
    }
  );
  return res.json() as any as AuthResult;
};
export const uploadUserProfile = async (file) => {
  const token = localStorage.getItem("__accessToken");
  const formData = new FormData();
  formData.append("file", file);
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/users/upload-profile-picture`,
    {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.blob();
};
export const getProfileImage = async () => {
  const token = localStorage.getItem("__accessToken");
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/users/get-user-photo`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return await res.blob();
};
export type AuthResult = {
  isSuccess;
  errors;
};
